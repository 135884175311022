'use strict';

var gtm = require('../gtm/gtmScript');
var redirectTicketMobiFacilImport = require('../ticket/redirecTicketMobiFacil');
var gtmHelper = require('../../../../scripts/helpers/gtmHelper');
var salesForceDataHelper = require('../../../../scripts/helpers/salesForceDataHelper');

var ticketForm = function () {
    $('#ticketForm').on('submit', function () {
        var obj = {
            origin: $('#origin').val(),
            destination: $('#destination').val(),
            departure: $('#inputDeparture').val(),
            arrival: $('#inputBack').val(),
            isStudent: $('#isStudent').prop('checked'),
            isPCD: $('#isPCD').prop('checked')
        };
        var redirectTicketMobiFacil = redirectTicketMobiFacilImport();
        redirectTicketMobiFacil.setInCache(obj);
        var eventObject = gtmHelper.createRequestObject(null, 'ticketSearch');
        if (eventObject) {
            gtm.executePush('ticketSearch', eventObject);
        }
    });
};
var listTrips = function () {
    $(document).ready(function () {
        var ticketButton = $('a.ticket__button.btn.btn-primary.d-inline-block.search-tickets-btn')[0];
        var parent = $(ticketButton).closest('.ticket__card');
        if (parent.length > 0) {
            salesForceDataHelper.updateSalesForceData(parent, 'search', null);
        } else {
            salesForceDataHelper.updateSalesForceData(null, 'search', null);
        }
    });

    $('a.ticket__button.btn.btn-primary.d-inline-block.search-tickets-btn').on('click', function (e) {
        e.preventDefault();
        var currentTarget = $(e.currentTarget);
        var list;
        var url;
        var parent = $(this).closest('.ticket__card');
        list = $(parent).data('list');
        var args = { list: list };
        var eventObject = gtmHelper.createRequestObject(parent, 'productClick', args);
        if (eventObject) {
            gtm.executePush('productClick', eventObject);
            salesForceDataHelper.updateSalesForceData(parent, 'search', null);
        }
        url = currentTarget.attr('href');
        document.location = url;
    });

    var connectionClassWrapper = $('.ticket__card').find('.ticket__tripsConnection--desktop');
    $(connectionClassWrapper).css({ 'z-index': '3' });
    $(connectionClassWrapper).on('click', function (e) {
        var parent = $(e.target.parentElement);
        var classClose = e.target.className;

        var ticketCard = $(parent.parent().closest('.ticket__card'));
        var unselected = ticketCard.siblings();

        var classModal = parent.find('#connectionModal');

        if (!classClose.includes('close')) {
            unselected.each(function (index, element) {
                $(element).find('.ticket__tripsConnection--desktop').css({ 'z-index': '0' });
            });

            classModal.addClass('show');
            classModal.css({
                display: 'block',
                'z-index': '100',
                'background-color': 'rgba(0,0,0,0.5)',
                transition: 'opacity 300ms ease-in-out'
            });
            $('.ticket__tag__wrap').css({ 'z-index': '2' });
        }
    });

    $('.modal-connection').find('.close').on('click', function (event) {
        event.preventDefault();
        $('.ticket__tripsConnection--desktop').each(function (index, element) {
            $(element).css({ 'z-index': '3' });
        });

        var modal = $(this)
            .parent()
            .parent()
            .parent()
            .parent();
        modal.removeClass('show');
        modal.css({
            display: 'none'
        });
    });
};

var onSubmitStoreInfo = function () {
    $('#ticketForm').on('submit', function () {
    // Set to localstorage the inputed data, so it can be used to populate inputs in case of errors;
        var searchedData = {
            origin: $('.cities__origin').val(),
            destination: $('.cities__destination').val(),
            departure: $('.dates__departure').val(),
            arrival: $('.dates__back').val(),
            isStudent: $('#isStudent').prop('checked'),
            isPCD: $('#isPCD').prop('checked'),
            originId: $('#origin').val(),
            destinationId: $('#destination').val(),
            departureId: $('#inputDeparture').val(),
            arrivalId: $('#inputBack').val()
        };
        localStorage.setItem('searchedData', JSON.stringify(searchedData));
    });
};


module.exports = {
    ticketForm: ticketForm,
    listTrips: listTrips,
    onSubmitStoreInfo: onSubmitStoreInfo
};
