function createSalesForceDataRequestObject() {
    if (!Object.hasOwnProperty.call(window, 'salesForceData')) {
        window.salesForceData = {
            addtocart: {},
            removefromcart: {},
            cancelorder: {},
            checkout: {},
            impressions: {},
            featured: { origin: '', destination: '' },
            purchase: { class: '', duration: '' },
            search: {
                origin: '', destination: '', departure: '', back: '', data: '', passagensnacategoriadeestudante: false, saida: '', chegada: ''
            }
        };
    }
}

function addToCartUpdateSalesForceData(eventObject) {
    createSalesForceDataRequestObject();

    if (eventObject) {
        window.salesForceData.addtocart = eventObject.ecommerce.add;
    }
}

function removeFromCartUpdateSalesForceData(eventObject) {
    createSalesForceDataRequestObject();

    if (eventObject) {
        window.salesForceData.removefromcart = eventObject.ecommerce.remove;
    }
}

function cancelUpdateSalesForceData(eventObject) {
    createSalesForceDataRequestObject();

    if (eventObject) {
        window.salesForceData.cancelorder = eventObject.ecommerce.orders;
    }
}

function checkoutUpdateSalesForceData(eventObject) {
    createSalesForceDataRequestObject();

    if (eventObject) {
        window.salesForceData.checkout = eventObject.ecommerce.checkout;
    }
}

function searchUpdateSalesForceData(element) {
    createSalesForceDataRequestObject();

    if ($(element).find('.ticket__trip').length > 0) {
        window.salesForceData.search.origin = $(element).find('.ticket__trip')[0].innerText;
        window.salesForceData.search.destination = $(element).find('.ticket__trip')[1].innerText;
    }

    if ($(element).find('.ticket__content div p').length > 0) {
        window.salesForceData.search.data = $(element).find('.ticket__content div p')[0].innerText;
    }

    var reg = new RegExp('[?&]dwfrm_ticketForm_isStudent=([^&#]*)', 'i');
    var string = reg.exec(window.location.href);
    window.salesForceData.search.passagensnacategoriadeestudante = string == null ? false : string[1] == 'true';

    // if ($(element).find('.ticket__time').length > 0) {
    //     window.salesForceData.search.saida = $(element).find('.ticket__time')[0].innerText;
    //     window.salesForceData.search.chegada =  $(element).find('.ticket__time')[1].innerText;
    // }

    // if ($(element).find('.ticket__price--wrapper h3').length > 0) {
    //     window.salesForceData.search.preco = $(element).find('.ticket__price--wrapper h3').text().trim();
    // }

    // if ($('.cities__input.cities__origin').length > 0) {
    //     window.salesForceData.search.origin = $('.cities__input.cities__origin').val()
    // }

    // if ($('.cities__input.cities__destination').length > 0) {
    //     window.salesForceData.search.destination = $('.cities__input.cities__destination').val()
    // }

    // if ($('#isStudent').length > 0) {
    //     window.salesForceData.search.passagensnacategoriadeestudante = $('#isStudent').prop('checked')
    // }
}

function impressionsUpdateSalesForceData(eventObject) {
    createSalesForceDataRequestObject();

    if (eventObject) {
        window.salesForceData.impressions = eventObject.ecommerce;
    }
}

function purchaseUpdateSalesForceData(element) {
    createSalesForceDataRequestObject();

    if ($(element).find('.ticket__tag').length > 0) {
        window.salesForceData.purchase.class = $(element).find('.ticket__tag').text().trim();
    }

    if ($(element).find('.ticket__duration').length > 0) {
        window.salesForceData.purchase.duration = $(element).find('.ticket__hours--wrap').find('.ticket__totalHours')[0].innerText.trim().split('h')[1].trim();
    }

    var parent = $('.ticket__card');

    if ($(parent).find('.ticket__trip').length > 0) {
        window.salesForceData.search.origin = $(parent).find('.ticket__trip')[0].innerText;
        window.salesForceData.search.destination = $(parent).find('.ticket__trip')[1].innerText;
    }

    if ($(element).find('.ticket__content div p').length > 0) {
        window.salesForceData.search.data = $(parent).find('.ticket__content div p')[0].innerText;
    }

    if ($(element).find('.ticket__time').length > 0) {
        window.salesForceData.search.saida = $(parent).find('.ticket__time')[0].innerText;
        window.salesForceData.search.chegada = $(parent).find('.ticket__time')[1].innerText;
    }
}

function updateSalesForceData(element, eventName, eventObject) {
    switch (eventName) {
        case 'add':
            addToCartUpdateSalesForceData(eventObject);
            break;
        case 'remove':
            removeFromCartUpdateSalesForceData(eventObject);
            break;
        case 'cancel':
            cancelUpdateSalesForceData(eventObject);
            break;
        case 'checkout':
            checkoutUpdateSalesForceData(eventObject);
            break;
        case 'search':
            searchUpdateSalesForceData(element);
            break;
        case 'impressions':
            impressionsUpdateSalesForceData(eventObject);
            break;
        case 'purchase':
            purchaseUpdateSalesForceData(element);
            break;
        default:
            break;
    }
}

module.exports = {
    updateSalesForceData: updateSalesForceData
};
