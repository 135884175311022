/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
'use strict';

module.exports = {
    executePush: function (eventName, eventObject) {
        try {
            if (dataLayer && dataLayer != null) {
                if (eventName && eventObject) {
                    if (eventObject.hasOwnProperty('ecommerce') && !eventObject.hasOwnProperty('eventCallback')) {
                        dataLayer.push({ event: eventName, ecommerce: eventObject.ecommerce });
                    }
                    if (eventObject.hasOwnProperty('ecommerce') && eventObject.hasOwnProperty('eventCallback')) {
                        dataLayer.push({ event: eventName, ecommerce: eventObject.ecommerce, eventCallback: eventObject.eventCallback });
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
};
