'use strict';

var RedirectTicketMobiFacil = function () {
    var initialTrip = {
        origin: '',
        destination: '',
        departure: '',
        arrival: '',
        createdAt: undefined,
        expiredAt: undefined,
        searched: false
    };
    var state = {
        trip: initialTrip,
        isExpired: false
    };

    var getFromCache = function () {
        try {
            var lastTrip = JSON.parse(sessionStorage.getItem('lastTrip'));
            if (!lastTrip) {
                lastTrip = initialTrip;
            }
            return lastTrip;
        } catch (error) {
            return initialTrip;
        }
    };

    var isSearchable = function () {
        if (!state.isExpired) {
            if (state.trip.createdAt && state.trip.expiredAt) {
                state.isExpired = new Date().getTime() > state.trip.expiredAt;
            }
        }
        return state.trip.searched ? !state.trip.searched : !state.isExpired;
    };

    var setInCache = function (trip) {
        try {
            var createdAt = new Date();
            var expiredAt = new Date().setMinutes(createdAt.getMinutes() + 3);
            sessionStorage.setItem('lastTrip', JSON.stringify(Object.assign({}, trip, { createdAt: createdAt, expiredAt: expiredAt })));
        } catch (error) {
            console.error(error);
        }
    };

    var generateURL = function () {
        return `https://www.mobifacil.com.br/busca?dwfrm_ticketForm_origin=${state.trip.origin}&dwfrm_ticketForm_destination=${state.trip.destination}&dwfrm_ticketForm_departure=${state.trip.departure.replaceAll('/', '%2F')}&dwfrm_ticketForm_back=${state.trip.arrival.replaceAll('/', '%2F')}`;
    };

    var hasSearchedOnMobiFacil = async function () {
        var resolve = await fetch(generateURL(), { method: 'GET', mode: 'no-cors' });
        if (resolve) {
            return !resolve.redirected;
        }
        return true;
    };

    var redirectToMobiFacil = function () {
        var link = generateURL();
        location.href = link;
    };

    var setSearched = function (searched) {
        state.trip.searched = searched;
        setInCache(state.trip);
    };

    var getTrip = function () {
        return state.trip;
    };

    var init = function () {
        state.trip = getFromCache();
    };

    init();

    return ({
        getTrip: getTrip,
        getFromCache: getFromCache,
        setInCache: setInCache,
        setSearched: setSearched,
        isSearchable: isSearchable,
        redirectToMobiFacil: redirectToMobiFacil,
        hasSearchedOnMobiFacil: hasSearchedOnMobiFacil
    });
};

module.exports = RedirectTicketMobiFacil;
