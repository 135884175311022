function createTicketSearchRequestObj() {
    var eventObject = {
        ecommerce:
            {
                click: {
                    Origem: $('.cities__input.cities__origin').val(),
                    Destino: $('.cities__input.cities__destination').val(),
                    Departure: $('#inputDeparture').val(),
                    Back: $('#inputBack').val(),
                    PassagensNaCategoriaDeEstudante: $('#isStudent').prop('checked')
                }
            }
    };

    return eventObject;
}

function createProductClickRequestObj(element, args) {
    var products = [];
    var product = {};

    var list = args.list;

    if ($(element).find('.ticket__trip').length > 0) {
        product.name = $(element).find('.ticket__trip')[0].innerText;
        product.Origem = $(element).find('.ticket__trip')[0].innerText;
        product.Destino = $(element).find('.ticket__trip')[1].innerText;
    }

    if ($(element).find('.ticket__tag').length > 0) {
        product.Classe = $(element).find('.ticket__tag').text().trim();
    }

    if ($(element).find('.ticket__content div p').length > 0) {
        product.Data = $(element).find('.ticket__content div p')[0].innerText;
    }

    if ($(element).find('.ticket__duration').length > 0) {
        product.Duration = $(element).find('.ticket__hours--wrap').find('.ticket__totalHours')[0].innerText.trim().split('h')[1].trim();
    }

    if ($(element).find('.ticket__time').length > 0) {
        product.Saida = $(element).find('.ticket__time')[0].innerText;
        product.Chegada = $(element).find('.ticket__time')[1].innerText;
    }

    if ($(element).find('.ticket__price--wrapper div').length > 0) {
        product.Preco = $(element).find('.ticket__price--wrapper div').text().trim();
    }
    // eslint-disable-next-line no-restricted-globals
    if ($(parent).find('.ticket__price--wrapper p').length > 0) { // eslint-disable-next-line no-restricted-globals
        product.PoltronasLivres = $(parent).find('.ticket__price--wrapper p').text();
    }

    var reg = new RegExp('[?&]dwfrm_ticketForm_isStudent=([^&#]*)', 'i');
    var string = reg.exec(window.location.href);
    product.PassagensNaCategoriaDeEstudante = string == null ? false : string[1] == 'true';

    products.push(product);

    var eventObject = {
        ecommerce:
            {
                click: {
                    actionField: { list: list },
                    products: products
                }
            }
    };

    return eventObject;
}

function createImpressionsRequestObj(element) {
    var event;
    var list;
    var products = [];
    $(element).each(function () {
        var product = {};
        event = $(this).data('event');
        list = $(this).data('list');

        if ($(this).find('.ticket__trip').length > 0) {
            product.name = $(this).find('.ticket__trip')[0].innerText;
            product.Origem = $(this).find('.ticket__trip')[0].innerText;
            product.Destino = $(this).find('.ticket__trip')[1].innerText;
        }

        if ($(this).find('.ticket__tag').length > 0) {
            product.Classe = $(this).find('.ticket__tag').text().trim();
        }
        if ($(this).find('.ticket__content div p').length > 0) {
            product.Data = $(this).find('.ticket__content div p')[0].innerText;
        }
        if ($(this).find('.ticket__duration').length > 0) {
            product.Duration = $(element).find('.ticket__hours--wrap').find('.ticket__totalHours')[0].innerText.trim().split('h')[1].trim();
        }
        if ($(this).find('.ticket__time').length > 0) {
            product.Saida = $(this).find('.ticket__time')[0].innerText;
            product.Chegada = $(this).find('.ticket__time')[1].innerText;
        }

        if ($(this).find('.ticket__price--wrapper div').length > 0) {
            product.Preco = $(this).find('.ticket__price--wrapper div').text().trim();
        }
        if ($(this).find('.ticket__price--wrapper p').length > 0) {
            product.PoltronasLivres = $(this).find('.ticket__price--wrapper p').text();
        }

        products.push(product);
    });

    if (products.length > 0 && event && list) {
        var eventObject = {
            ecommerce: {
                actionField: { list: list },
                detail: {
                    products: products
                }
            },
            event: event
        };

        return eventObject;
    }
}

function createRemoveFromCartRequestObj(element) {
    var products = [];
    var product = {};

    element.each(function () {
        if ($(this).find('.location-from').length > 0) {
            product.name = $(this).find('.location-from span')[0].innerText;
            product.Origem = $(this).find('.location-from span')[0].innerText;
            product.Destino = $(this).find('.location-to span')[0].innerText;
        }

        if ($(this).find('.date').length > 2) {
            product.Data = $(this).find('.date-and-hour')[1].innerText;
        }

        if ($(this).find('.price').length > 0) {
            product.Preco = $(this).find('.price')[0].innerText;
        }

        if ($(this).find('.passengers').length > 0) {
            product.Quantidade = $(this).find('.passengers')[0].innerText;
        }

        products.push(product);

        var eventObject = {
            ecommerce:
                {
                    remove: {
                        products: products
                    }
                }
        };

        return eventObject;
    });
}

function createCheckoutRequestObj(element) {
    var products = [];
    var product = {};

    element.each(function () {
        if ($(this).find('.location-from').length > 0) {
            product.name = $(this).find('.location-from span')[0].innerText;
            product.Origem = $(this).find('.location-from span')[0].innerText;
            product.Destino = $(this).find('.location-to span')[0].innerText;
        }

        if ($(this).find('.date').length > 2) {
            product.Data = $(this).find('.date-and-hour')[1].innerText;
        }

        if ($(this).find('.price').length > 0) {
            product.Preco = $(this).find('.price').text().trim();
        }

        if ($(this).find('.passengers').length > 0) {
            product.Quantidade = $(this).find('.passengers').text().trim();
        }

        products.push(product);

        var eventObject = {
            ecommerce:
                {
                    checkout: {
                        products: products
                    }
                }
        };

        return eventObject;
    });
}

function createCheckoutConfirmationRequestObj(element) {
    var products = [];

    $(element).each(function () {
        var product = {};

        if ($(this).find('.ticket__trip').length > 0) {
            product.name = $(this).find('.ticket__trip')[0].innerText;
            product.Origem = $(this).find('.ticket__trip')[0].innerText;
            product.Destino = $(this).find('.ticket__trip')[1].innerText;
        }

        if ($(this).find('.ticket__header div p').length > 0) {
            product.TipoViagem = $(this).find('.ticket__header div p').text().trim();
        }

        if ($(this).find('.ticket__header p').length > 0) {
            product.Data = $(this).find('.ticket__header p')[3].innerText;
        }

        if ($(this).find('.ticket__time').length > 0) {
            product.Saida = $(this).find('.ticket__time')[0].innerText;
            product.Chegada = $(this).find('.ticket__time')[1].innerText;
        }

        if ($(this).find('.ticket__content-totalPrice p').length > 0) {
            product.Quantidade = $(this).find('.ticket__content-totalPrice p')[0].innerText;
            product.Preco = $(this).find('.ticket__content-totalPrice p')[1].innerText;
        }

        products.push(product);
    });

    var eventObject = {
        ecommerce:
            {
                checkout: {
                    actionField: { step: 1, option: 'Cielo' },
                    products: products
                }
            }
    };

    return eventObject;
}

function createPurchaseRequestObj(element) { // product-summary-block
    var products = [];
    var revenue = 0;
    var actionField;

    $('.order__card').each(function () {
        var productElement = $(this).find(element).find('.product-line-item');
        var product = {};

        if ($(productElement).find('.ticket__trip').length > 0) {
            product.id = $(productElement).find('.id__label')[0].innerText;
        }

        products.push(product);
    });

    if ($('.order__card').length > 0) {
        actionField = {
            id: $('.order__infos strong')[0].innerText,
            revenue: Number($('.order__card').find('.order__infos--price')[0].innerText.replace(/[^\d,]+/g, '').replace(',', '.'))
        };
    }

    var eventObject = {
        event: 'purchase',
        ecommerce:
            {
                purchase: {
                    actionField: {
                        id: actionField ? actionField.id : '',
                        revenue: actionField ? actionField.revenue : revenue
                    },
                    products: products
                }
            }
    };

    return eventObject;
}

function createCancelOrderRequestObj(element) {
    var orders = [];

    element.each(function () {
        var order = {};
        if ($(this).find('.order__destinations-js').length > 0) {
            var data = $(this).find('.order__destinations-js').data();
            order.name = data.origin;
            order.Origem = data.origin;
            order.Destino = data.destination;
        }

        if ($(this).find('.order__header.clearfix p').length > 0) {
            order.Data = $(this).find('.order__header.clearfix p')[2].innerText.trim();
        }

        if ($(this).find('.ticket__time').length > 0) {
            order.Saida = $(this).find('.ticket__time')[0].innerText.trim();
            order.Chegada = $(this).find('.ticket__time')[1].innerText.trim();
        }

        if ($(this).find('.order__infos').length > 0) {
            order.Pedido = $(this).find('.order__infos')[0].innerText.trim().split('\n')[1];
            order.DataPedido = $(this).find('.order__infos')[1].innerText.trim().split('\n')[1];
        }

        order.Cancelamento = new Date();

        var productLineItems = $('.product-line-item');
        var seats = [];

        productLineItems.each(function () {
            var seat = {};

            seat.name = $(this).find('.details__content h3').text().trim();

            seat.id = $(this).find('.id__label').text().trim();

            seat.Poltrona = $(this).find('p.seat__number').text().trim();

            seat.Origem = $(this).find('.ticket__trip')[0].innerText.trim();

            seat.Destino = $(this).find('.ticket__trip')[1].innerText.trim();

            seat.Data = $(this).find('.date')[0].innerText.split('\n')[1];

            seat.Cancelamento = new Date();

            seats.push(seat);
        });

        order.seats = seats;

        orders.push(order);
    });

    var eventObject = {
        ecommerce:
            {
                orders:
                {
                    orders: orders
                }
            }
    };

    return eventObject;
}

function createCancelOrderSeatRequestObj(element, args) {
    var orders = [];

    element.each(function () {
        var order = {};
        if ($(this).find('.order__destinations-js').length > 0) {
            var data = $(this).find('.order__destinations-js').data();
            order.name = data.origin;
            order.Origem = data.origin;
            order.Destino = data.destination;
        }

        if ($(this).find('.order__header.clearfix p').length > 0) {
            order.Data = $(this).find('.order__header.clearfix p')[2].innerText.trim();
        }

        if ($(this).find('.ticket__time').length > 0) {
            order.Saida = $(this).find('.ticket__time')[0].innerText.trim();
            order.Chegada = $(this).find('.ticket__time')[1].innerText.trim();
        }

        if ($(this).find('.order__infos').length > 0) {
            order.Pedido = $(this).find('.order__infos')[0].innerText.trim().split('\n')[1];
            order.DataPedido = $(this).find('.order__infos')[1].innerText.trim().split('\n')[1];
        }

        if (args.cancelOrder) {
            order.Cancelamento = new Date();
        }

        var productLineItems = $(args.event).closest('.product-line-item');
        var seats = [];

        productLineItems.each(function () {
            var seat = {};

            seat.name = $(this).find('.details__content h3').text().trim();

            seat.id = $(this).find('.id__label').text().trim();

            seat.Poltrona = $(this).find('p.seat__number').text().trim();

            seat.Origem = $(this).find('.ticket__trip')[0].innerText.trim();

            seat.Destino = $(this).find('.ticket__trip')[1].innerText.trim();

            seat.Data = $(this).find('.date')[0].innerText.split('\n')[1];

            seat.Cancelamento = new Date();

            seats.push(seat);
        });

        order.seats = seats;

        orders.push(order);
    });

    var eventObject = {
        ecommerce:
            {
                orders:
                {
                    orders: orders
                }
            }
    };

    return eventObject;
}

function createAddSeatClickRequestObj(element, args) {
    var products = [];
    var product = {};

    if ($(element).length > 0) {
        if ($(element).find('.ticket__trip').length > 0) {
            product.name = $(element).find('.ticket__trip')[0].innerText;
            product.Origem = $(element).find('.ticket__trip')[0].innerText;
            product.Destino = $(element).find('.ticket__trip')[1].innerText;
        }

        if ($(element).find('.ticket__tag').length > 0) {
            product.Classe = $(element).find('.ticket__tag').text().trim();
        }

        if ($(element).find('.ticket__content div p').length > 0) {
            product.Data = $(element).find('.ticket__content div p')[0].innerText;
        }

        if ($(element).find('.ticket__hours--wrap').find('.ticket__totalHours').length > 0) {
            product.Duration = $(element).find('.ticket__hours--wrap').find('.ticket__totalHours')[0].innerText.trim().split('h')[1].trim();
        }

        if ($(element).find('.ticket__time').length > 0) {
            product.Saida = $(element).find('.ticket__time')[0].innerText;
            product.Chegada = $(element).find('.ticket__time')[1].innerText;
        }
        // eslint-disable-next-line no-restricted-globals
        if ($(parent).find('.ticket__price--wrapper div').length > 0) { // eslint-disable-next-line no-restricted-globals
            product.Preco = $(parent).find('.ticket__price--wrapper div').text().trim();
        }

        if ($(element).find('.ticket__price--wrapper p').length > 0) {
            product.PoltronasLivres = $(element).find('.ticket__price--wrapper p').text();
        }

        product.QuantidadeDePoltronas = $('#seatNumberInfo')[0].innerText;
        product.Preco = $('#seatPriceInfo')[0].innerText;

        product.Estudante = args.student;

        products.push(product);

        var eventObject = {
            ecommerce:
                {
                    add:
                    {
                        products: products
                    }
                }
        };

        return eventObject;
    }
}

function createRemoveSeatClickRequestObj(element, args) {
    var products = [];
    var product = {};

    if ($(element).length > 0) {
        if ($(element).find('.ticket__trip').length > 0) {
            product.name = $(element).find('.ticket__trip')[0].innerText;
            product.Origem = $(element).find('.ticket__trip')[0].innerText;
            product.Destino = $(element).find('.ticket__trip')[1].innerText;
        }

        if ($(element).find('.ticket__tag').length > 0) {
            product.Classe = $(element).find('.ticket__tag').text().trim();
        }

        if ($(element).find('.ticket__content div p').length > 0) {
            product.Data = $(element).find('.ticket__content div p')[0].innerText;
        }

        if ($(element).find('.ticket__duration').length > 0) {
            product.Duration = $(element).find('.ticket__hours--wrap').find('.ticket__totalHours')[0].innerText.trim().split('h')[1].trim();
        }

        if ($(element).find('.ticket__time').length > 0) {
            product.Saida = $(element).find('.ticket__time')[0].innerText;
            product.Chegada = $(element).find('.ticket__time')[1].innerText;
        }
        // eslint-disable-next-line no-restricted-globals
        if ($(parent).find('.ticket__price--wrapper div').length > 0) { // eslint-disable-next-line no-restricted-globals
            product.Preco = $(parent).find('.ticket__price--wrapper div').text().trim();
        }

        if ($(element).find('.ticket__price--wrapper p').length > 0) {
            product.PoltronasLivres = $(element).find('.ticket__price--wrapper p').text();
        }

        product.QuantidadeDePoltronas = $('#seatNumberInfo')[0].innerText;
        product.Preco = $('#seatPriceInfo')[0].innerText;

        product.Estudante = args.student;

        products.push(product);

        delete product.name;
        product.id = $('.seat-number', args.innerSeat).text();
        if (product.id != '') {
            products.push(product);

            var eventObject = {
                ecommerce:
                    {
                        remove:
                        {
                            products: products
                        }
                    }
            };

            return eventObject;
        }
    }
}

// function createUpsellNl(element) {
//     console.log(element); // Ver qual o propósito dessa função.
//     return;
// }

function createRequestObject(element, eventName, args) {
    switch (eventName) {
        case 'productClick':
            return createProductClickRequestObj(element, args);
        case 'ticketSearch':
            return createTicketSearchRequestObj();
        case 'impressions':
            return createImpressionsRequestObj(element);
        case 'removeFromCart':
            return createRemoveFromCartRequestObj(element);
        case 'checkout':
            return createCheckoutRequestObj(element);
        case 'checkoutConfirmation':
            return createCheckoutConfirmationRequestObj(element);
        case 'cancelOrder':
            return createCancelOrderRequestObj(element);
        case 'cancelOrderSeat':
            return createCancelOrderSeatRequestObj(element, args);
        case 'add':
            return createAddSeatClickRequestObj(element, args);
        case 'remove':
            return createRemoveSeatClickRequestObj(element, args);
        case 'purchase':
            return createPurchaseRequestObj(element);
        // case 'Upsell_NL':
        //     return createUpsellNl(element);
        default:
            break;
    }
}

module.exports = {
    createRequestObject: createRequestObject
};
